import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', {
  state: () => ({
    overflow: false, //dawne hide_overflow
  }),
  actions: {
    toggleOverflow(status: boolean) {
      const el = document.getElementsByTagName('html')[0];
      const className = 'overflow-hidden';
      if (status) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
      this.overflow = status;
    }
  },
  getters: {
    getOverflow: (state) => state.overflow,
  }
})